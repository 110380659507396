import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {graphql} from 'gatsby'
import {formatData, withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import {VacancyImages, ErrorBoundary, ProductTiles, Banner, Layout, PageLayout, RichText, Testimonials} from '/src/components'
import {mapTestimonialsToImage, options, templatePageSeoProps} from '/src/helpers'
import {useI18n} from '/src/i18n'
import {desktop} from '/src/styles/breakpoints.module.less'

const NAMES = ['clara', 'tiemo', 'lisa']

const InnerPage = ({data}) => {
	const {asset, tiles} = formatData({data})
	const {vacancy} = data
	const {salaryRange, text} = vacancy
	const [t] = useI18n()
	const location = t('common.in_amsterdam')
	const description = `${salaryRange}. ${location}`
	const colleagues = mapTestimonialsToImage(NAMES, asset)

	return (
		<ErrorBoundary>
			<Banner type="vacancy" {...vacancy} description={description} marginTop={60} />
			<RichText content={text} options={options} />
			<Banner type="talk_to_you" marginTop={60} />
			<ProductTiles type="our_philosophy" items={tiles} />
			<Banner type="future_colleagues" marginTop={100} maxWidth={desktop}>
				<Testimonials items={colleagues} />
			</Banner>

			<VacancyImages assets={asset} />
			<Banner type="talk_to_you" margin="60 - 120 -" />
		</ErrorBoundary>
	)
}

const Page = ({data}) => {
	const {name} = data.vacancy
	const {seo} = formatData({data})
	const seoProps = templatePageSeoProps(name, seo)

	return (
		<PageLayout name="vacancy" {...seoProps}>
			<InnerPage data={data} />
		</PageLayout>
	)
}

export const query = graphql`
	query ($slug: String, $locale: String) {
		contentfulPage(slug: {eq: "vacancy"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
		}

		allContentfulAsset(
			filter: {
				node_locale: {eq: $locale}
				title: {
					in: [
						"office clara"
						"office street"
						"team happy"
						"office abel"
						"Tiemo portret - lage resolutie"
						"Clara portret - lage resolutie"
						"Lisa portret - lage resolutie"
					]
				}
			}
		) {
			edges {
				node {
					title
					gatsbyImageData
				}
			}
		}
		allContentfulTile(sort: {order: ASC}, filter: {node_locale: {eq: $locale}, tags: {eq: "vacancy"}}) {
			edges {
				node {
					title
					icon
					subtitle
				}
			}
		}
		contentfulVacancy(node_locale: {eq: $locale}, slug: {eq: $slug}) {
			text {
				raw
			}
			updatedAt
			salaryRange
			name
			slug
		}
	}
`

export default withFlattenedResponse(Page)
